export const flatListToHierarchical = (
  data = [],
  { idKey = "id", parentKey = "parentId", childrenKey = "children" } = {}
) => {
  const tree = [];
  const childrenOf = {};
  data
    .sort((a, b) => a.order - b.order)
    .forEach((item, index) => {
      const newItem = { ...item, index: index };
      const { [idKey]: id, [parentKey]: parentId = 0 } = newItem;
      childrenOf[id] = childrenOf[id] || [];
      newItem[childrenKey] = childrenOf[id];
      parentId
        ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem)
        : tree.push(newItem);
    });
  return tree;
};

export const loadZnanyLekarz = ($_x, _s, id) => {
  let js,
    fjs = $_x.getElementsByTagName(_s)[0];
  if (!$_x.getElementById(id)) {
    js = $_x.createElement(_s);
    js.id = id;
    js.src = "//platform.docplanner.com/js/widget.js";
    fjs.parentNode.insertBefore(js, fjs);
  }
};

export const cleanUpZnanyLekarz = ($_x, id) => {
  let widget = $_x.getElementById(id);
  if (widget) {
    widget.remove();
  }
};

/*
F7B2BD
077187
96ACB7
DBE9EE
C0D6DF
AED9E0
E2A3C7
FFC6D9
9ED8DB
8AE1FC


Zespół:
Maciej Schmidt
Justyna Barton
Regulamin
Oferta
Pomoc psychologiczna
Pomoc seksuologiczna
Porada seksuologiczna on-line
Blog
Kontakt

 */
